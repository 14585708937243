<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Groupes et privil&egrave;ges</template>
    <b-container v-if="account">
      <h3>Compte utilisateur</h3>
      <ul>
        <li>{{ account.email }}</li>
      </ul>
      <h3>Contacts</h3>
      <ul>
        <li v-for="contact in account.contacts" :key="contact.codeContact">
          <a href="#" @click="contactCourant = contact">
            {{ contact.prenom }} {{ contact.nom }} ({{ contact.raisonSociale }} {{ contact.codeTiers }})
          </a>
        </li>
      </ul>
      <div v-if="contactCourant">
        <h4>Groupes</h4>
        <ul>
          <li v-for="groupe in contactCourant.groupes" :key="groupe">
            {{ groupe }}
          </li>
        </ul>
        <h4>Privilèges</h4>
        <ul>
          <li v-for="(privilege, index) in contactCourant.privileges" :key="index">
              {{ privilege.codePrivilege }}
              <template v-if="privilege.codesSociete.length > 1">
                sur {{ privilege.codesSociete.join(', ') }}
              </template>
          </li>
        </ul>
      </div>
    </b-container>
    <template #modal-footer>
      <b-button block pill variant="outline-primary" @click="cancel">
        Fermer
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AccountGroupsDialog",
  data: () => ({
    account: null,
    contactCourant: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options) {
      this.account = options.account;
      this.contactCourant = null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
